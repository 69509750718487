<template>
    <section class="newCoringsValidationPopIn">
        <v-card
            flat
            outlined
            class="newCoringsValidationPopIn__card"
        >
            <h3>Valider l'envoi au laboratoire ?</h3>

            <v-card-text class="card_text">
                <p>
                    Voulez-vous soumettre votre campagne et ses données à votre
                    prestataire d'analyses ?
                </p>

                <v-spacer />

                <p>Après envoi, cette campagne ne sera plus modifiable.</p>

                <p>
                    <b>
                        Nous vous rappelons que ceci est un engagement contractuel.<br /><br />
                        Nombre de prestations: <br />
                        <ul>
                            <li>{{ AsbestosPrestationsCount }} Amiante</li>
                            <li>{{ PAHPrestationsCount }} HAP</li>
                            <li>{{ THPrestationsCount }} HCT</li>
                        </ul>
                    </b>
                </p>

                <p
                    v-if="!hasPrestations"
                    class="danger"
                >
                    Vous devez d'abord paramétrer les prestations de cette campagne<br />
                </p>

                <p v-if="isInternalOrganization">
                    <b>Pensez à réaliser votre commande sur GX.</b>
                </p>
            </v-card-text>

            <v-form @submit.prevent="confirmSending">
                <v-col
                    cols="8"
                    md="8"
                    class="references"
                >
                    <v-text-field
                        tabindex="1"
                        class="mt-1"
                        label="Référence dossier NextRoad *"
                        required
                        outlined
                        clearable
                        v-model="form.nextroadRef"
                        :rules="[$rules.required, $rules.max255]"
                        counter="255"
                        dense
                        validate-on-blur
                    />

                    <v-text-field
                        tabindex="2"
                        class="mt-1"
                        :label="(hasGroupement && isProviderEurofins() ? 'Référence BDC amiante vers laboratoire' : 'Référence Bdc vers laboratoire') + ' *'"
                        :persistent-hint="hasGroupement && isProviderEurofins()"
                        :hint="hasGroupement && isProviderEurofins() ? 'N° de la commande amiante suite regroupement' : false"
                        outlined
                        clearable
                        v-model="form.customerOrderRef"
                        :rules="[$rules.required, $rules.max255, $rules.referenceIsNotAlreadyUsed]"
                        counter="255"
                        dense
                    />

                    <v-text-field
                        v-if="hasGroupement && isProviderEurofins()"
                        tabindex="2"
                        class="mt-1"
                        label="Référence BDC HAP vers laboratoire *"
                        persistent-hint
                        hint="N° de la commande HAP suite regroupement"
                        outlined
                        clearable
                        v-model="form.secondaryCustomerOrderRef"
                        :rules="[$rules.required, $rules.max255]"
                        counter="255"
                        dense
                    />

                    <v-select
                        v-if="isProviderEurofins()"
                        outlined
                        tabindex="3"
                        dense
                        class="mt-1"
                        label="Choix du laboratoire*"
                        :items="labs"
                        :item-text="(value) => `${value.name} (${value.code}) `"
                        :item-value="(value) => value.id"
                        v-model="providerLaboratory"
                    />
                </v-col>
            </v-form>

            <v-card-actions class="justify-center">
                <MediumButtonSlot @click="closePopin">
                    Annuler
                </MediumButtonSlot>

                <v-spacer />

                <HighButtonSlot
                    @click="redirectToPrestationSettings()"
                    v-if="needButton"
                >
                    {{ !hasPrestations ? 'Paramétrer les prestations' : 'Modifier les prestations' }}
                </HighButtonSlot>

                <HighButtonSlot :_disabled="!isFormValid" @click="confirmSending">Valider</HighButtonSlot>
            </v-card-actions>
        </v-card>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'SendCoringCampaignToProviderValidationPopin',
    props: [
        'site',
        'campaign',
        'needButton',
        'open',
        'provider',
        'hasGroupement',
        'AsbestosPrestationsCount',
        'PAHPrestationsCount',
        'THPrestationsCount',
    ],
    data () {
        return {
            hasPrestations: false,
            form: {
                nextroadRef: null,
                customerOrderRef: null,
                secondaryCustomerOrderRef: null,
            },
            isDisabled: false,
            oldCustomerRef: null,
            labs: [],
            providerLaboratory: null,
        };
    },
    computed: {
        isFormValid () {
            if (this.isDisabled) {
                return false;
            }

            if (false === this.hasPrestations) {
                return false;
            }

            if (null === this.form.nextroadRef) {
                return false;
            }

            if (null === this.form.customerOrderRef) {
                return false;
            }

            if (
                this.isProviderEurofins()
                && this.hasGroupement
                && (this.form.secondaryCustomerOrderRef ?? '').trim().length === 0
            ) {
                return false;
            }

            return true;
        },
    },
    watch: {
        open () {
            if (this.open === true) {
                this.hasCampaignPrestations();
                if (this.isProviderEurofins()) {
                    this.getProviderLaboratories();
                }
            }
        },
    },
    methods: {
        ...mapActions('coring', ['setCampaignStatus', 'forceUpdateCores']),
        isInternalOrganization () {
            return '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3' === this.site.organization.id;
        },
        redirectToPrestationSettings () {
            this.$router.push({
                name: 'coring-campaign-prestations',
                params: { id: this.$route.params.id },
            });
        },
        closePopin () {
            this.$emit('closePopin', false);
        },
        async confirmSending () {
            this.isDisabled = true;

            await this.$api.coringCampaigns.sendToProvider(
                this.$route.params.id,
                {
                    providerLaboratoryId: this.providerLaboratory?.id ?? null,
                    mainOrderNumber: this.form.customerOrderRef,
                    secondaryOrderNumber: this.form.secondaryCustomerOrderRef,
                },
            );

            await this.forceUpdateCores(this.$route.params.id);
            this.closePopin();
        },
        async hasCampaignPrestations () {
            const prestations = await this.$api.campaigns.getPrestations(
                this.$route.params.id,
            );

            this.hasPrestations = prestations.length > 0;
        },
        async getProviderLaboratories () {
            if (this.provider) {
                this.labs = await this.$api.providers.getLabByProviderId(this.provider.id);
                this.providerLaboratory = this.labs[0];
            } else {
                let providers = await this.$api.providers.findAll();
                let provider = providers.find(e => e.name === this.campaign.provider);
                this.labs = await this.$api.providers.getLabByProviderId(provider.id);
                this.providerLaboratory = this.labs[0];
            }
        },
        isProviderEurofins () {
            return [3, 5].includes(this.provider.id);
        },
    },
    async mounted () {
        this.$root.$on('closeCurrent', this.closePopin);
        this.hasCampaignPrestations();
        if (this.provider?.id === 3 || this.provider?.id === 5) {
            this.getProviderLaboratories();
        }
        if (this.campaign !== undefined) {
            this.form.nextroadRef = this.campaign.nextroadRef;
            this.form.customerOrderRef = this.campaign.customerOrderRef;
            if (this.campaign.campaignAnalyse !== null && this.campaign.campaignAnalyse !== undefined && this.campaign.campaignAnalyse?.length !== 0) {
                let test = this.campaign.campaignAnalyse.find(e => e.orderNumber === this.form.customerOrderRef);
                if (test !== undefined) {
                    this.oldCustomerRef = test.orderNumber;
                }
            }
        }
    },

};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.newCoringsValidationPopIn {
  @extend %popinView;

  .v-card {
    width: 50%;
    padding: 20px 20px;
  }

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .buttonsContainer button {
    margin: 5px;
  }

  .danger {
    color: red !important;
  }

  .references {
    margin: auto;
    padding-top: 0;
  }
}
</style>
